import styled from 'styled-components'
import { headingFont, sh, lg, md, xl } from 'config/variables'

export const DevelopmentStackStyle = styled.section`
  margin: 0 0 139px 0;
  ${xl(`
    margin-bottom: 90px;
  `)}
  ${lg(`
    margin-bottom: 90px;
  `)}
  ${md(`
    margin-bottom: 60px;
  `)}
  .heading-wrapper {
    max-width: 720px;
    margin-bottom: 43px;
    ${sh(`
      margin-bottom: 33px;
    `)}
    ${md(`
      margin-bottom: 25px;
    `)}
  }
  .heading-para {
    max-width: 922px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 74px;
    ${sh(`
      margin-bottom: 60px;
    `)}
    ${lg(`
      margin-bottom: 54px;
    `)}
    ${md(`
      margin-bottom: 44px;
    `)}
    p {
      font-size: 25px;
      line-height: 34px;
      ${sh(`
        font-size: 22px;
        line-height: 31px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 29px;
      `)}
    }
  }
  .stack-listing-block {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    .single-stack-block {
      background: linear-gradient(287deg, #a98bfd, #d3c4ff);
      border-radius: 3.5px;
      display: flex;
      align-items: center;
      padding-left: 104px;
      position: relative;
      margin-bottom: 15px;
      ${md(`
        padding: 0px;
        flex-wrap: wrap;
      `)}
      .title {
        position: absolute;
        font-size: 16.8px;
        letter-spacing: -0.18px;
        font-family: ${headingFont};
        font-weight: 700;
        display: inline-block;
        max-width: 150px;
        width: 100%;
        text-align: center;
        left: -25px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        ${md(`
          position: static;
          transform: initial;
          padding: 20px 15px 20px;
          width: 100%;
          text-align: center;
          max-width: 100%;
        `)}
      }
      .stack-listing {
        max-width: 794px;
        width: 100%;
        padding: 22px 32px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-radius: 3.5px;
        ${md(`
          padding-top: 0;
          display: none;
        `)}
        .single-stack {
          margin: 8px;
          width: calc(33.33% - 16px);
          background: #fff;
          box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.06);
          border-radius: 5.6px;
          font-size: 14px;
          line-height: 16.8px;
          letter-spacing: -0.15px;
          text-align: center;
          padding: 16.5px 15px 17.5px;
          font-family: ${headingFont};
          display: flex;
          align-items: center;
          justify-content: center;
          ${md(`
            max-width: 200px;
            width: 100%;
            display: none;
          `)}
          &:first-child{
            ${md(`
              display: block;
            `)}
          }
        }
      }
      .separator-row {
        max-width: 730px;
        width: 100%;
        right: 32px;
        bottom: -27px;
        display: flex;
        justify-content: space-around;
        position: absolute;
        z-index: 1;
        ${lg(`
          max-width: 75%;
        `)}
        ${md(`
          max-width: 100%;
          right: 0;
        `)}
        .divider {
          height: 35px;
          width: 3px;
          border-left: 3px dotted #b7f3ff;
        }
      }
      &:nth-child(2) {
        background: transparent;
        ${md(`
          background: linear-gradient(
            309deg,
            rgba(203, 244, 255, 0.8),
            rgba(10, 216, 255, 0.8)
          );
        `)}
        .stack-listing {
          background: linear-gradient(
            309deg,
            rgba(203, 244, 255, 0.8),
            rgba(10, 216, 255, 0.8)
          );
          ${md(`
            background: transparent;
            );
          `)}
        }
      }
      &:nth-child(3) {
        background: linear-gradient(287deg, #ffcd68, #fff1d6);
      }
      &:last-child {
        background: linear-gradient(287deg, #7FA8FF, #C7DAFF);
        .separator-row {
          display: none;
        }
      }
    }
  }
`
