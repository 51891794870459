import React, { memo, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/Seo'
import { AboutProps } from '../components/Props/about'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import AboutBanner from 'components/AboutBanner/AboutBanner'
import DevelopmentStack from 'components/DevelopmentStack/DevelopmentStack'
import LeadershipTeam from 'components/LeadershipTeam/LeadershipTeam'
import OpeningJobSection from 'components/OpeningJobSection/OpeningJobSection'
import MealMenuSection from 'components/MealMenuSection/MealMenuSection'
import { useAnimationClass } from 'components/hooks/useAnimationClass'

export const AboutPage = styled.div``

const DDT = memo(props => {
  const { data } = props
  const freshteams = useRef()
  const { location } = props
  useAnimationClass()

  useEffect(() => {
    if (freshteams.current) {
      let script = document.createElement('script')
      script.src =
        'https://s3.amazonaws.com/files.freshteam.com/production/13065/attachments/3000614878/original/3000006457_widget.js?1562240930'
      document.body.appendChild(script)
    }
  }, [freshteams])

  return (
    <Layout mainClass="about-page" location={location}>
      <SEO
        title="About Us"
        keywords={[`simform`]}
        description="Tech company on a mission to help companies extend their tech capacity. We help you decide the right architecture and oversee the successful delivery of your software projects."
        links={[
          {
            href: 'https://www.simform.com/about-us/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/about-us/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <AboutPage>
        <AboutBanner
          aboutBannerData={AboutProps}
          {...props}
          showImages={false}
        />
        {/* <DevelopmentStack developementStackData={AboutProps} /> */}
        <LeadershipTeam leadershipTeamData={AboutProps} {...props} />
        <OpeningJobSection openingJobSecdata={AboutProps} {...props} />
        <MealMenuSection mealmenusecData={AboutProps} {...props} />
      </AboutPage>
    </Layout>
  )
})

export default DDT

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/about-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 848) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    prayaagkasundra: file(relativePath: { regex: "/prayaag-pic@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hirendhaduk: file(relativePath: { regex: "/hiren-dhaduk-pic@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hardikshah: file(relativePath: { regex: "/hardik-pic@2x/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    maitrikkataria: file(
      relativePath: { regex: "/about-team-maitrik-pic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dhvanildesai: file(
      relativePath: { regex: "/about-team-dhvanil-pic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    rohitakiwatkar: file(
      relativePath: { regex: "/about-team-rohit-pic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    tejaskaneriya: file(
      relativePath: { regex: "/about-team-tejas-pic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justinmitchell: file(
      relativePath: { regex: "/about-team-justin-pic@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    abhinavDahagam: file(relativePath: { regex: "/abhinav-dahagam@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mihirShah: file(relativePath: { regex: "/mihir-shah@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 210) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    OpenJobImg: file(relativePath: { regex: "/own-growth-path@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 506) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    meal1: file(relativePath: { regex: "/meal1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    meal2: file(relativePath: { regex: "/meal2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    meal3: file(relativePath: { regex: "/meal3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 357) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    work1: file(relativePath: { regex: "/work1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    work2: file(relativePath: { regex: "/work2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    work3: file(relativePath: { regex: "/work3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 357) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
