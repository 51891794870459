import styled from 'styled-components'
import { sh, xl, lg, md, sm } from 'config/variables'

export const MealMenuSectionStyle = styled.section`
  margin: 0;
  padding-bottom: 110px;
  border-bottom: 1px solid rgba(216,213,213,0.5);
  ${lg(`
    padding-bottom: 90px;
  `)}
  ${md(`
    padding-bottom: 50px;
  `)}
  .menu-listing-row {
    max-width: 1060px;
    width: 100%;
    margin: 0 auto;
    margin-top: 56px;
    .single-row {
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${sh(`
        margin-bottom: 80px;
      `)}
      ${lg(`
        margin-bottom: 80px;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      `)}
      ${sm(`
        margin-bottom: 60px;
      `)}
      &:nth-child(2n) {
        flex-direction: row-reverse;
        ${lg(`
          flex-direction: column-reverse;
        `)}
        .img-block {
          .col-left {
            right: 0;
            left: initial;
          }
          .col-right {
            .row {
              margin-left: 0;
              &:last-child {
                margin-left: 30px;
                margin-right: 0;
              }
            }
          }
        }
      }
      .content-blk {
        max-width: 394px;
        width: 100%;
        .content-row {
          margin-bottom: 85px;
          ${lg(`
            margin-bottom: 60px;
          `)}
          ${sm(`
            margin-bottom: 40px;
          `)}
          .icon {
            margin-bottom: 14px;
          }
          h4 {
            font-size: 30px;
            line-height: 38px;
            letter-spacing: 0.2px;
            margin-bottom: 12px;
            font-weight: 500;
            ${sh(`
              font-size: 26px;
              line-height: 34px;
            `)}
            ${md(`
              font-size: 24px;
              line-height: 32px;
            `)}
          }
          p {
            font-size: 24px;
            line-height: 32px;
            margin: 0;
            ${sh(`
              font-size: 21px;
              line-height: 29px;
            `)}
            ${md(`
              font-size: 20px;
              line-height: 28px;
            `)}
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .img-block {
        max-width: 555px;
        width: 100%;
        position: relative;
        ${sh(`
          max-width: 515px;
        `)}
        ${xl(`
          max-width: 515px;
        `)}
        ${lg(`
          max-width: 515px;
          margin-bottom: 40px;
        `)}
        ${md(`
          max-width: 421px;
        `)}
        ${sm(`
          max-width: 300px;
        `)}
        .col-left {
          position: absolute;
          width: 320px;
          z-index: 9;
          top: 98px;
          left: 0;
          ${sh(`
            width: 300px;
          `)}
          ${lg(`
            width: 300px;
          `)}
          ${md(`
            width: 250px;
          `)}
          ${sm(`
            width: 200px;
            top: 70px;
          `)}
        }
        .col-right {
          .row {
            margin-left: auto;
            margin-right: 0;
            max-width: 320px;
            ${sh(`
              max-width: 300px;
            `)}
            ${xl(`
              max-width: 300px;
            `)}
            ${lg(`
              max-width: 300px;
            `)}
            ${md(`
              width: 250px;
            `)}
            ${sm(`
              width: 200px;
            `)}
            &:last-child {
              margin-top: 20px;
              margin-right: 30px;
              margin-left: auto;
              max-width: 357px;
              position: relative;
              z-index: 9;
              ${sh(`
                max-width: 337px;
              `)}
              ${xl(`
                max-width: 337px;
              `)}
              ${lg(`
                max-width: 337px;
              `)}
              ${md(`
                width: 280px;
              `)}
              ${sm(`
                width: 230px;
              `)}
            }
          }
        }
      }
    }
  }
  .btn-row {
    text-align: center;
    padding-top: 40px;
    ${lg(`
      padding-top: 20px;
    `)}
    ${md(`
      padding-top: 0px;
    `)}
  }
`
