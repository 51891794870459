import styled from 'styled-components'
import { lg, md, sm, xl } from 'config/variables'

export const LeadershipTeamStyle = styled.section`
  margin: 0;
  position: relative;
  z-index: 1;
  .container{
    ${md(`
      padding: 0;
    `)}
    .heading-wrapper{
      ${md(`
        padding: 0 30px;
      `)}
      ${sm(`
        padding: 0 20px;
      `)}
    }
    .team-listing-block {
      display: flex;
      flex-wrap: wrap;
      margin-top: 62px;
      ${md(`
        margin-top: 60px;
      `)}
      .team-listing-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 38px;
        width: 100%;
        ${lg(`
          margin-bottom: 20px;
        `)}
        ${md(`
          margin-bottom: 45px;
          margin-left: 0;
          padding: 22px 22px 0 0;
          overflow: auto;
          display: block;
          -webkit-overflow-scrolling: touch;
        `)}
        .team-listing {
          max-width: 1046px;
          width: 100%;
          margin: 22px;
          background: rgba(199, 221, 243, 0.7);
          position: relative;
          padding: 20px 0 22px;
          ${xl(`
            margin-left: 0px;
          `)}
          ${lg(`
            margin-left: 0px;
          `)}
          ${md(`
            max-width: 830px;
            width: 830px;
            padding: 16px 16px 16px 32px;
            margin: 0;
            margin-left: 20px;
          `)}
          .listing-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            right: -22px;
            top: -22px;
            background: #b6d1ec;
            z-index: -1;
            ${sm(`
              right: -16px;
              top: -16px;
            `)}
          }
          .single-member-wrapper {
            display: flex;
            ${md(`
              width: 800px;
            `)}          
            .single-member {
              flex: 33.33% 0;
              padding: 0 42px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              ${lg(`
                  padding: 0 35px;
                `)}
              ${md(`
                flex: 1 0 200px;
                padding: 0 10px;
                margin-bottom: 0px;
                min-width: 200px;
              `)}
              .heading {
                padding: 0 0;
                margin-bottom: 40px;
                ${xl(`
                  padding: 0 25px;
                  margin-bottom: 30px;
                `)}
                ${lg(`
                  padding: 0px;
                  margin-bottom: 20px;
                `)}
                ${md(`
                  padding: 0 20px;
                `)}
                .name {
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 500;
                  display: block;
                }
                .designation {
                  font-size: 14px;
                  line-height: 19px;
                  display: block;
                  margin-bottom: 10px;
                }
              }
              .img-wrapper {
                display: block;
                .gatsby-image-wrapper {
                  margin-right: 0 auto !important;
                }
              }
            }
          }
        }
        &:nth-child(2n) {
          justify-content: flex-start;
          .team-listing {
            background: rgba(232, 228, 244, 0.7);
            .listing-bg {
              background: #d6ceed;
            }
          }
        }
      }
    }
  }
`
