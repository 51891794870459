import React, { Component } from 'react'
import { LeadershipTeamStyle } from './LeadershipTeam.styles'
import Image from 'components/ImgOptimized'

class LeadershipTeam extends Component {
  render() {
    const { leadershipTeamProps } = this.props.leadershipTeamData
    const {
      data: {
        prayaagkasundra: {
          childImageSharp: { fluid: prayaagkasundra },
        },
        hirendhaduk: {
          childImageSharp: { fluid: hirendhaduk },
        },
        hardikshah: {
          childImageSharp: { fluid: hardikshah },
        },
        maitrikkataria: {
          childImageSharp: { fluid: maitrikkataria },
        },
        dhvanildesai: {
          childImageSharp: { fluid: dhvanildesai },
        },
        rohitakiwatkar: {
          childImageSharp: { fluid: rohitakiwatkar },
        },
        tejaskaneriya: {
          childImageSharp: { fluid: tejaskaneriya },
        },
        justinmitchell: {
          childImageSharp: { fluid: justinmitchell },
        },
        abhinavDahagam: {
          childImageSharp: { fluid: abhinavDahagam },
        },
        mihirShah: {
          childImageSharp: { fluid: mihirShah },
        },
      },
    } = this.props
    let items = [
      [prayaagkasundra, hirendhaduk, hardikshah],
      [maitrikkataria, justinmitchell, abhinavDahagam],
      [dhvanildesai, mihirShah, tejaskaneriya],
      [rohitakiwatkar],
    ]
    return (
      <LeadershipTeamStyle>
        <div className="container">
          <div className="heading-wrapper">
            <h3>{leadershipTeamProps.heading}</h3>
          </div>
          <div className="team-listing-block">
            {leadershipTeamProps.teamListProps.map((teamList, i) => {
              return (
                <div key={i} className="team-listing-wrapper">
                  <div className="team-listing">
                    <div className="listing-bg" />
                    <div className="single-member-wrapper">
                      {teamList.memberListProps.map((memberList, j) => {
                        return (
                          <div key={`${i}${j}`} className="single-member">
                            <div className="heading">
                              <div className="name">{memberList.name}</div>
                              <div className="designation">
                                {memberList.designation}
                              </div>
                            </div>
                            <div className="img-wrapper">
                              {items[i] && (
                                <Image
                                  fluid={items[i][j]}
                                  lazyload={{
                                    enabled: true,
                                    once: true,
                                    offset: 1000,
                                    heigth: 250,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </LeadershipTeamStyle>
    )
  }
}

export default LeadershipTeam
