import React from 'react'
import { images } from 'config/images'

export const AboutProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Team that Future Proofs your{' '}
        <span className="heading-red">Organization with Gen AI and Cloud</span>
      </>
    ),

    bannerContent: (
      <>
        Nearly every organization will have to become a tech company in order to
        compete tomorrow. Yes, even yours. At Simform, we are on a mission to
        build Gen AI enterprises for tomorrow and develop competitiveness and
        agility using the power of Gen AI/ML, Cloud and Product Engineering
        services.
        <br />
        <br />
        Simform, an AWS Premier Tier Partner, is consistently listed as the top
        US software development company on Clutch's leaderboard. Founded in
        2010, we have helped organizations ranging from Startups that went
        public, to Fortune 500 companies, and progressive Enterprises.
        <br />
        <br />
        At the core we are a product engineering company with a mission to solve
        complex software engineering problems. Our Product Innovation Center
        transforms your engineering from being bottlenecks to growth drivers.
        Our AI Center of Excellence (CoE) implements Gen AI driven product
        features and accelerates your path towards realizing AI/ML ROI.
      </>
    ),
    banner: images.dvdBanner,
    BannerAlt: 'About Simform',
  },
  developmentStackProps: {
    heading: (
      <>
        We are your <span className="highlight-head">development stack</span>
      </>
    ),
    mainPara: (
      <>
        You run your business. We take care of your development needs. Get
        access to an entire team of experts, ready{' '}
        <span className="color-through">whenever you need us.</span>
      </>
    ),
    stackListBlock: [
      {
        title: (
          <>
            Accelerate <br />
            Digital Innovation
          </>
        ),
        stackList: [
          'Digital Transformation',
          'Custom Software',
          'Business Applications',
          'Software Products',
          'API/System Integration',
          'Mobile Apps',
          'SaaS',
          'Consumer Apps',
        ],
      },
      {
        title: (
          <>
            Extend <br />
            Tech Capacity
          </>
        ),
        stackList: [
          'Proven Processes ',
          'Remote Team Culture',
          'QA & Testing',
          'Mentors and Tech Leads',
          'Code Quality',
          'Effortless Hiring at Scale',
          'Guaranteed Results',
          'Security',
          'Tools and Cloud Servicing',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Business Goals
          </>
        ),
        stackList: [
          'Your Tech Team',
          'Digital Transformation Plans',
          'Product Development',
          'System Integration',
          'Modernization',
        ],
      },
      {
        title: (
          <>
            Your <br />
            Organization
          </>
        ),
        stackList: [
          'Sales & Marketing',
          'Operations',
          'Human Resource',
          'Finance',
          'Production',
          'Research and Development',
        ],
      },
    ],
  },
  leadershipTeamProps: {
    heading: (
      <>
        Leadership <span className="highlight-head">Team</span>
      </>
    ),
    teamListProps: [
      {
        memberListProps: [
          {
            name: 'Prayaag Kasundra',
            designation: '— CEO',
          },
          {
            name: 'Hiren Dhaduk',
            designation: '— CTO',
          },
          {
            name: 'Hardik Shah',
            designation: '— Head, Sales',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Maitrik Kataria',
            designation: '— CEO, North America',
          },
          {
            name: 'Justin Mitchell',
            designation: 'COO USA',
          },
          {
            name: 'Abhi Dahagam',
            designation: '— Head, AWS',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Dhvanil Desai',
            designation: '- VP, Delivery (AWS)',
          },
          {
            name: 'Mihir Shah',
            designation: '- VP, Delivery',
          },
          {
            name: 'Tejas Kaneriya',
            designation: '— Head, Business Analysis',
          },
        ],
      },
      {
        memberListProps: [
          {
            name: 'Rohit Akiwatkar',
            designation: '— Director of Marketing',
          },
        ],
      },
    ],
  },
  openingJobSecdProps: {
    heading: <>Choose your own growth path</>,
    buttonName: 'Explore our openings',
    buttonUrl: '/careers/',
  },
  mealmenuSectionProps: {
    heading: <>Unleash your potential</>,
    menuListing: [
      {
        singleBlock: [
          {
            icon: images.iconHealthBenefits,
            title: 'Health Benefits',
            content: (
              <>
                We work best when the people we care are healthy. Our{' '}
                <strong>health program</strong> empowers you to be worry-free.
              </>
            ),
          },
          {
            icon: images.iconMentorshipAnd,
            title: 'Mentorship and Learning',
            content: (
              <>
                Never stop learning by getting an online or{' '}
                <strong>in-person mentoring</strong> on ANY topic you want to
                grow in.
              </>
            ),
          },
        ],
      },
      {
        singleBlock: [
          {
            icon: images.iconFlexibility,
            title: 'Flexibility to work',
            content: (
              <>
                Stay <strong>inspired to work</strong> however and whatever
                technologies you find your best productive self in.
              </>
            ),
          },
          {
            icon: images.iconLevelUp,
            title: 'Level up career',
            content: (
              <>
                Work with internationally recognized clients and take ownership
                of how you <strong>work to build things.</strong>
              </>
            ),
          },
        ],
      },
    ],
    buttonUrl: '/careers/',
    buttonName: 'Explore our openings',
  },
}
